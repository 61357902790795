import { PropertyConverter } from './property-converter.interface';

export class RemoveUnderscoreConverter implements PropertyConverter {
  deserialize(value: any) {
    if (value || value === 0) {
      return String(value).replace('_', '0');
    } else {
      return value;
    }
  }

  serialize(value: any) {
    return value;
  }
}
