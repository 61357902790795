import { Model, Attribute } from './decorators';
import { BaseApiModel } from './base';
import { ConfigurationType } from '@parashift/shared/types';
import { NumberConverter } from './converters/number.converter';
import { RemoveUnderscoreConverter } from './converters/remove-underscore.converter';

export enum BarcodeType {
  'datamatrix' = 'datamatrix',
  'Code128' = 'Code128',
  'Code39' = 'Code39',
  'EAN-13' = 'EAN-13',
  'EAN-8' = 'EAN-8',
  'QRCODE' = 'QRCODE',
  'PDF417' = 'PDF417',
  'MICRO-PDF417' = 'MICRO-PDF417',
  'ITF' = 'ITF',
}

const SeparationType = {
  intelligent: $localize`:@@constants.separation_type.intelligent:Intelligent` as 'intelligent',
  barcode: $localize`:@@constants.separation_type.barcode:Barcode` as 'barcode',
  nth_page: $localize`:@@constants.separation_type.nth_page:Fixed Page Interval` as 'nth_page',
  parashift_page: $localize`:@@constants.separation_type.parashift_page:Parashift Page` as 'parashift_page',
  input_file: $localize`:@@constants.separation_type.input_file:by File` as 'input_file'
}
type SeparationType = (typeof SeparationType)[keyof typeof SeparationType];
export { SeparationType };

const CutPosition = {
  after: $localize`:@@constants.separation_cut_position.after:after` as 'after',
  at: $localize`:@@constants.separation_cut_position.at:at` as 'at',
  before: $localize`:@@constants.separation_cut_position.before:before` as 'before'
}
type CutPosition = (typeof CutPosition)[keyof typeof CutPosition];
export { CutPosition };

const ClassificationMode = {
  llm: $localize`:@@constants.classification_mode.llm:LLM` as 'llm',
  page_embedding: $localize`:@@constants.classification_mode.page_embedding:Classic` as 'page_embedding'
}
type ClassificationMode = (typeof ClassificationMode)[keyof typeof ClassificationMode];
export { ClassificationMode };

export interface SeparationMethod {
  separation_type: SeparationType;
  arguments: SeparationMethodArguments
}

export interface SeparationMethodArguments {
  barcode_separation_cut_position?: CutPosition;
  barcode_separation_pattern?: string;
  barcode_separation_type?: BarcodeType;
  nth_page_separation_interval?: number;
}

const OcrMode = {
  'ocr+pdf': $localize `:@@common.ocr_and_pdf:OCR & PDF text` as 'ocr+pdf',
  ocr: $localize `:@@common.ocr:OCR only` as 'ocr',
  pdf: $localize `:@@common.pdf:PDF text only` as 'pdf'
};
type OcrMode = (typeof OcrMode)[keyof typeof OcrMode];
export { OcrMode };

export interface ConfigurationPlainModel {
  id: string;
  classification_first_level_tenant_id: number;
  classification_mode: ClassificationMode;
  classification_threshold: number;
  classification_qc_rate: number;
  classification_qc_tenant_id: number;
  classification_second_level_tenant_id: number;
  classification_third_level_tenant_id: number;
  created_at: string;
  custom_source_file_types: string;
  documents_per_month: number;
  extraction_first_level_tenant_id: number;
  extraction_qc_rate: number;
  extraction_qc_tenant_id: number;
  extraction_second_level_tenant_id: number;
  extraction_third_level_tenant_id: number;
  fallback_document_type_id: number;
  inbound_email: string;
  inbound_email_active: boolean;
  name: string;
  priority_sla: boolean;
  priority_processing: boolean;
  sla_minutes_slow: number;
  sla_minutes_fast: number;
  identifier: string;
  ocr_mode: OcrMode;
  qc_external_rate: number;
  qc_external_tenant_id: number;
  qc_internal_rate: number;
  qc_internal_tenant_id: number;
  separation_first_level_tenant_id: number;
  separation_lower_threshold: number
  separation_qc_rate: number;
  separation_qc_tenant_id: number;
  separation_second_level_tenant_id: number;
  separation_third_level_tenant_id: number;
  separation_thresholds: [number, number];
  separation_types: SeparationMethod[];
  separation_upper_threshold: number
  separation_validation_required: boolean;
  temp_inbound_email_active?: boolean;
  create_output_file: boolean;
  tenant_id: number;
  updated_at: string;
  workflow_arguments: WorkflowArguments;
}

export interface WorkflowArguments {
  classification?: { [key: string]: string };
  extraction?: { [key: string]: string };
  separation?: { [key: string]: string };
}

@Model({ type: ConfigurationType })
export class Configuration extends BaseApiModel<ConfigurationPlainModel> {

  @Attribute({ converter: new NumberConverter() })
  classification_first_level_tenant_id: number;

  @Attribute()
  classification_mode: ClassificationMode;

  @Attribute({ converter: new RemoveUnderscoreConverter() })
  classification_threshold: number;

  @Attribute()
  classification_qc_rate: number;

  @Attribute({ converter: new NumberConverter() })
  classification_qc_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  classification_second_level_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  classification_third_level_tenant_id: number;

  @Attribute({ readonly: true })
  created_at: string;

  @Attribute()
  custom_source_file_types: string[];

  @Attribute()
  documents_per_month: number;

  @Attribute({ converter: new NumberConverter() })
  extraction_first_level_tenant_id: number;

  @Attribute()
  extraction_qc_rate: number;

  @Attribute({ converter: new NumberConverter() })
  extraction_qc_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  extraction_second_level_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  extraction_third_level_tenant_id: number;

  @Attribute()
  fallback_document_type_id: number;

  @Attribute()
  identifier: string;

  @Attribute({ readonly: true })
  inbound_email: string;

  @Attribute()
  inbound_email_active: boolean;

  @Attribute()
  name: string;

  @Attribute()
  ocr_mode: OcrMode | null;

  @Attribute()
  priority_sla: boolean;

  @Attribute()
  priority_processing: boolean;

  @Attribute()
  sla_minutes_slow: number;

  @Attribute()
  sla_minutes_fast: number;

  @Attribute()
  qc_external_rate: number;

  @Attribute({ converter: new NumberConverter() })
  qc_external_tenant_id: number;

  @Attribute()
  qc_internal_rate: number;

  @Attribute({ converter: new NumberConverter() })
  qc_internal_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  separation_first_level_tenant_id: number;

  @Attribute()
  separation_lower_threshold: number

  @Attribute()
  separation_qc_rate: number;

  @Attribute({ converter: new NumberConverter() })
  separation_qc_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  separation_second_level_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  separation_third_level_tenant_id: number;

  @Attribute()
  separation_types: SeparationMethod[];

  @Attribute()
  separation_upper_threshold: number

  @Attribute()
  separation_validation_required: boolean;

  @Attribute()
  create_output_file: boolean;

  @Attribute()
  tenant_id: number;

  @Attribute({ readonly: true })
  updated_at: string;

  @Attribute()
  workflow_arguments: WorkflowArguments;

  get plainModel() {
    const model = this.toHash() as ConfigurationPlainModel;
    model.custom_source_file_types = this.getCustomSourceFileTypes(this.custom_source_file_types);
    model.separation_thresholds = [
      this.separation_lower_threshold || this.separation_lower_threshold === 0 ? this.separation_lower_threshold : 0.80,
      this.separation_upper_threshold || this.separation_upper_threshold === 0 ? this.separation_upper_threshold : 0.95
    ];

    return model;
  }

  set plainModel(model) {
    this.customUpdates = {
      custom_source_file_types: setCustomSourceFileTypes,
      separation_lower_threshold: setSeparationLowerThreshold,
      separation_upper_threshold: setSeparationUpperThreshold,
    };

    this.setPlainModel(model);
    this.ensureParashiftSeparationProperties();
  }

  getCustomSourceFileTypes(extraAllowedTypes: string[]) {
    if (extraAllowedTypes) {
      return extraAllowedTypes.join('\n');
    }
    return undefined;
  }

  private ensureParashiftSeparationProperties() {
    for(const type of this.separation_types){
      if (type.separation_type === 'parashift_page') {
        type.arguments.barcode_separation_type = BarcodeType.QRCODE;
        type.arguments.barcode_separation_cut_position = 'at' as CutPosition;
        type.arguments.barcode_separation_pattern = 'https://www.parashift.io/en/42';
      }
    }
  }
}

function setCustomSourceFileTypes(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  const mimeTypes = updatedPlainModel[key].split(/\r?\n/).filter((mt: string) => mt);
  existingModel[key] = mimeTypes;
}

function setSeparationLowerThreshold(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel['separation_thresholds'] && Array.isArray(updatedPlainModel['separation_thresholds'])) {
    existingModel[key] = updatedPlainModel['separation_thresholds'][0];
  } else {
    existingModel[key] = updatedPlainModel[key];
  }
}

function setSeparationUpperThreshold(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel['separation_thresholds'] && Array.isArray(updatedPlainModel['separation_thresholds'])) {
    existingModel[key] = updatedPlainModel['separation_thresholds'][1];
  } else {
    existingModel[key] = updatedPlainModel[key];
  }
}
